import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';

export const colors = {
  malachite: '#0EDE8E',
  aluminium: '#A3AFBB',
  iron: '#D6DBE0',
  black: '#000',
  white: '#fff',
  purpleBlue: '#5924EB',
  platinum: '#E5E5E5',
  ghost: '#F7F9FA',
  boulder: '#717379',
  ultramarine: '#2B58F8',
  midnight: '#090D2E',
  silver: '#C4C4C4',
  smoke: '#F5F6F8',
  mercury: '#E6E6E6',
  rosso: '#EE0004',
  steel: '#7E8196',
  cobalt: '#0030DC',
  alabaster: '#F8F9FD',
  cloudburst: '#2D254D',
  mint: '#0EC17C',
  kiwi: '#B2F642',
  aureolin: '#EDF115',
  mango: '#F1C236',
  raspberry: '#FC006A',
  riverbed: '#475462',
  clearblue: '#3988FF',
  geyser: '#D9E0E7',
  coralblue: '#BADEFF',
  fog: '#C3DBFF',
  orchid: '#AD22C3',
  frenchrose: '#FF4689',
  rosepink: '#FFCAD7',
  lilac: '#D846EF',
  seashell: '#EEEFF3',
  dusk: '#4A4073',
  nebula: '#D5DBE0',
};

const theme = createTheme({
  palette: {
    primary: { main: colors.purpleBlue },
    success: { main: colors.malachite },
    secondary: { main: colors.aluminium, contrastText: colors.white },
    info: { main: colors.iron },
    background: {
      default: colors.smoke,
    },
    text: {
      primary: colors.midnight,
    },
    error: {
      main: colors.rosso,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 52,
          boxShadow: 'none',
          textTransform: 'capitalize',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(colors.midnight, 0.5),
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 320,
      tabletMin: 620,
      minTablet: 700,
      tablet: 835,
      minDesktop: 1000,
      desktop: 1360,
    },
  },
});

export default theme;
