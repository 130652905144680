import { css, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import theme, { colors } from '../../theme';
import { FONTS } from '../../constants';

const ContentWrapper = styled('div')`
  padding: 40px 128px;
  
  display: flex;
  justify-content: center;
  
  ${(props) => {
    if (props.mobile) {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
`;

const Cross = styled(CrossIcon)`
  cursor: pointer;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.1);
    path {
      fill: ${theme.palette.primary.main};
    };
},
`;

const InternalLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: ${colors.black};
  font: normal normal 400 14px/17.5px ${FONTS};
  
  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

export default {
  Cross,
  ContentWrapper,
  InternalLink,
};
