import React, { useCallback } from 'react';
// import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import SidebarMenuButton from '../Burger';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import S from './styled';
import SidebarNavMenu from './SidebarNavMenu';

function NonAuthHeader({
  isSidebarOpen,
  toggleSidebar,
  navigateToSignIn,
  navigateToSignUp,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tablet),
  );

  const navigateAndCloseSidebar = useCallback(
    (navigateFn) => () => {
      navigateFn();
      toggleSidebar();
    },
    [toggleSidebar],
  );

  if (isMobile) {
    return (
      <S.Header>
        <S.Content mobile={isMobile}>
          <S.Left mobile={isMobile}>
            {/* <SidebarMenuButton
              open={isSidebarOpen}
              toggleMenu={toggleSidebar}
            /> */}
            <a href="./" rel="noopener noreferrer">
              <Logo />
            </a>
          </S.Left>
          <SidebarNavMenu
            open={isSidebarOpen}
            navigateToSignIn={navigateAndCloseSidebar(navigateToSignIn)}
            navigateToSignUp={navigateAndCloseSidebar(navigateToSignUp)}
            onLinkClick={toggleSidebar}
            mobile
          />
        </S.Content>
      </S.Header>
    );
  }

  return (
    <S.Header>
      <S.Content>
        <S.Left>
          {/* <SidebarMenuButton open={isSidebarOpen} toggleMenu={toggleSidebar} /> */}
          <a href="./" rel="noopener noreferrer">
            <Logo />
          </a>
        </S.Left>

        {/* <S.HeaderButtons>
          <Button color="info" variant="contained" onClick={navigateToSignIn}>
            Sign In
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={navigateToSignUp}
          >
            Registration
          </Button>
        </S.HeaderButtons> */}

        <SidebarNavMenu open={isSidebarOpen} onLinkClick={toggleSidebar} />
      </S.Content>
    </S.Header>
  );
}

export default NonAuthHeader;
