import { ReactComponent as Overview } from '../assets/icons/overview.svg';
import { ReactComponent as Deposit } from '../assets/icons/deposit.svg';
import { ReactComponent as Personal } from '../assets/icons/personal.svg';
import { ReactComponent as Reports } from '../assets/icons/reports.svg';
import { ReactComponent as Portfolio } from '../assets/icons/portfolio.svg';

export const FONTS = '"Suisse Int\'l", sans-serif';

export const MAX_WIDTH = 1440;

export const OVERVIEW_TABS = [
  {
    name: 'Overview',
    destination: '',
  },
  {
    name: 'History',
    destination: 'history',
  },
  {
    name: 'Distribution',
    destination: 'distribution',
  },
  {
    name: 'Transactions',
    destination: 'transactions',
  },
  {
    name: 'Portfolio Detailed',
    destination: 'portfolio',
  },
];

export const APP_ACTIONS_MENU = [
  {
    name: 'Overview',
    icon: Overview,
    destination: '/overview',
  },
  {
    name: 'Deposit',
    icon: Deposit,
    destination: '/deposit',
  },
  {
    name: 'Personal',
    icon: Personal,
    destination: '/personal',
  },
  {
    name: 'Reports',
    icon: Reports,
    destination: '/reports',
  },
  {
    name: 'Portfolio',
    icon: Portfolio,
    destination: '/portfolio',
  },
];

export const OVERVIEW_INITIAL_DATA = {
  balance: {
    ending_nav: 0,
    cumulative_return: 0,
  },
  deposits_withdrawals: 0,
  history_benchmark: [],
  history_allocation: [],
  geo: [],
  classes: [],
  portfolio_detailed: [],
  risk_profiles: [],
  transactions: [],
};

export const HISTORY_LOG_TYPE = {
  BUTTON: 'button',
  FIELD: 'field',
  EMAIL: 'email',
};
