import { styled } from '@mui/material';

const Wrapper = styled('section')`
  padding: 200px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Wrapper,
};
