import { useMemo } from 'react';
import { useAuthorization, useOverview, useUser } from '../contexts';

const useDisable = () => {
  const { user } = useUser();
  const { isAuth } = useAuthorization();
  const { overviewData } = useOverview();

  const isDisable = useMemo(
    () =>
      !user?.onboardingID ||
      !user?.IBUID ||
      user?.onboardingComplited !== 100 ||
      overviewData?.deposits_withdrawals === '0' ||
      !isAuth,
    [
      user?.onboardingID,
      user?.IBUID,
      user?.onboardingComplited,
      overviewData?.deposits_withdrawals,
      isAuth,
    ],
  );

  return { isDisable };
};

export default useDisable;
