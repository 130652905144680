import React from 'react';
import Button from '@mui/material/Button';
import S from './styled';
import { useAuthorization } from '../../contexts';

function SidebarNavMenu({
  open,
  /* onLinkClick, */ mobile = false,
  navigateToSignIn,
  navigateToSignUp,
}) {
  const { isAuth } = useAuthorization();

  return (
    <S.Sidebar open={open} mobile={mobile}>
      {/* <S.SidebarItem mobile={mobile} to="/settings" onClick={onLinkClick}> */}
      {/*  Settings & personal details */}
      {/* </S.SidebarItem> */}
      {/* <S.SidebarItem mobile={mobile} to="/documents" onClick={onLinkClick}> */}
      {/*  Upload documents */}
      {/* </S.SidebarItem> */}
      {/* <S.SidebarItem mobile={mobile} to="/reports" onClick={onLinkClick}> */}
      {/*  Reports */}
      {/* </S.SidebarItem> */}
      {/* <S.SidebarItem mobile={mobile} to="/portfolio" onClick={onLinkClick}> */}
      {/*  Portfolio analyst */}
      {/* </S.SidebarItem> */}
      {/* <S.SidebarItem mobile={mobile} to="/deposit-and-withdraw" onClick={onLinkClick}> */}
      {/*  Deposit &amp; Withdrawal */}
      {/* </S.SidebarItem> */}

      {mobile && !isAuth && (
        <S.MobileActionButtons>
          <Button color="info" variant="contained" onClick={navigateToSignIn}>
            Sign In
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={navigateToSignUp}
          >
            Registration
          </Button>
        </S.MobileActionButtons>
      )}

      {mobile && isAuth && (
        <S.MobileActionButtons>
          <Button color="info" variant="contained" onClick={navigateToSignIn}>
            Log Out
          </Button>
        </S.MobileActionButtons>
      )}
    </S.Sidebar>
  );
}

export default SidebarNavMenu;
