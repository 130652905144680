import { styled, css } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import theme, { colors } from '../../theme';
import { FONTS, MAX_WIDTH } from '../../constants';

const HeaderButtons = styled('div')`
  display: flex;
  
  button {
    font: normal normal 400 15px/19px ${FONTS};
    letter-spacing: 0;
    padding-left: 32px;
    padding-right: 32px;
  }

  button:last-of-type {
    margin-left: 16px;
  }
`;

const Header = styled('header')`
  
  height: 74px;
  background-color: ${colors.white};
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
`;

const Left = styled('div')`
  display: flex;
  align-items: center;
  
  svg {
    margin-left: 15px;
  }
  
  ${(props) => {
    if (props.mobile) {
      return css`
        justify-content: center;
        width: 100%;
     `;
    }
    return css``;
  }}
`;

const Sidebar = styled('div')`
  padding: 56px 48px 52px;
  background-color: ${colors.smoke};
  backdrop-filter: blur(90px);
  position: absolute;
  top: 74px;
  left: 0;
  z-index: -1;
  width: 100%;
  max-width: 375px;
  height: calc(100vh - 74px);
  box-shadow: 5px 10px 10px ${colors.iron};

  transition: transform 0.2s linear;  
  
  ${(props) => {
    if (!props.open) {
      return css`
        transform: translateX(-100%);
    `;
    }
    return css``;
  }}
  
  ${(props) => {
    if (props.mobile) {
      return css`
        padding: 56px 16px 24px;
      `;
    }
    return css``;
  }}
`;

const SidebarItem = styled(Link)`
  display: block;
  padding-top: 25px;
  padding-bottom: 23px;
  border-top: 1px solid ${colors.mercury};
  color: ${colors.black};
  text-decoration: none;

  font: normal normal 400 32px/40px ${FONTS};
  letter-spacing: -1px;
  
  &:first-of-type {
    border-top: none;
  }
  
  &:hover {
    background-color: ${colors.mercury};
  }

  ${(props) => {
    if (props.mobile) {
      return css`
      font: normal normal 400 20px/25px ${FONTS};
      letter-spacing: -0.4px;
      margin-right: 54px;
      `;
    }
    return css``;
  }}
`;

const NavItem = styled(NavLink)`
  &:not(:last-child) {
    margin-right: 30px;
  }
  
  font: normal normal 500 16px/20px ${FONTS};
  text-decoration: none;
  color: ${colors.black};

  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  
  &.active {
    //border-bottom: 2px solid ${theme.palette.text.primary};
    &:after {
      content: " ";
      display: block;
      background-color: ${theme.palette.text.primary};
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 20px;
    }
  }
`;

const Nav = styled('nav')`
  height: 100%;
`;

const Content = styled('div')`
  max-width: ${MAX_WIDTH}px;
  height: 100%;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 36px;
  padding-right: 32px;
  
  ${(props) => {
    if (props.mobile) {
      return css`
      padding-left: 16px;
      padding-right: 35px;
    `;
    }
    return css``;
  }}
`;

const MobileActionButtons = styled('div')`
  display: flex;
  // TODO: Temporary
  //margin-top: 31px;

  button {
    font: normal normal 400 14px/18px ${FONTS};
    letter-spacing: 0;
    padding-left: 32px;
    padding-right: 32px;
    min-width: 108px;
  }

  button:last-of-type {
    margin-left: 8px;
    width: 100%;
    max-width: 225px;
  }
`;

export default {
  HeaderButtons,
  Header,
  Left,
  Sidebar,
  SidebarItem,
  NavItem,
  Nav,
  Content,
  MobileActionButtons,
};
