import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import S from './styled';
import { ReactComponent as AppStore } from '../../assets/icons/appStore.svg';
import { ReactComponent as GooglePlay } from '../../assets/icons/googlePlay.svg';
import { FONTS } from '../../constants';
import finos from '../../assets/images/Finos.svg';
import vqf from '../../assets/images/VQF.svg';
import swissMade from '../../assets/images/swissMade.svg';
import { colors } from '../../theme';
import swissFlag from '../../assets/images/swiss.png';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '1440px',
    margin: 'auto',
    height: '100%',
    padding: '50px 63px 64px 65px',
    display: 'flex',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 41px',
      flexDirection: 'column',
      paddingBottom: 61,
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
    },
  },
  button: {
    height: 55,
    marginBottom: 32,
    padding: '16px 24px',
    '&:first-of-type': {
      marginRight: 32,
    },
    [theme.breakpoints.up('mobile')]: {
      width: 238,
      border: '2px solid #000000',
      boxSizing: 'border-box',
      borderRadius: '8px',
      height: '42px',
      background: '#fff',
      '& svg': {
        width: '80px',
        height: '20px',
        display: 'block',
      },
      '& path': {
        fill: 'black',
      },
    },
    [theme.breakpoints.up('desktop')]: {
        background: "#5924EB" ,
        border: "none",
        borderRadius: "4px",
        width: "142px",
        height: "55px",
        display: "flex",
        alignItems: "center",
        marginRight: "32px",
        justifyContent: "center",
        float: "left",
        '& svg': {
          width: '101px',
          height: 'auto',
        },
        '& path': {
          fill: 'white',
        },
    },
  },
  copyright: {
    font: `normal normal 400 10px/13px ${FONTS}`,
    letterSpacing: 1,
    color: theme.palette.secondary.main,
    marginTop: 0,
    marginBottom: 0,
    clear: "both",
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('mobile')]: {
      maxWidth: 238,
      whiteSpace: 'normal',
    },
    [theme.breakpoints.up('tabletMin')]: {
      maxWidth: 'none',
    },
  },
  download: {
    marginRight: 25,
    [theme.breakpoints.up('mobile')]: {
      marginBottom: 35,
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: 0,
    },
  },
  sponsors: {
    opacity: 0.4,
    marginBottom: 32,
    '& > img:not(:last-of-type)': {
      marginRight: 84,
      [theme.breakpoints.up('mobile')]: {
        marginRight: 0,
        maxWidth: "100%"
      },
      [theme.breakpoints.up('desktop')]: {
        marginRight: 84,
      },
    },
    [theme.breakpoints.up('mobile')]: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: 32,
    },
    [theme.breakpoints.up('tabletMin')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
 
  },
  information: {
    display: 'grid',
    gridTemplateColumns: '416px 416px',
    columnGap: 33,
    rowGap: 32,
    '& > p': {
      marginTop: 0,
      marginBottom: 0,
      font: `normal normal 400 10px/13px ${FONTS}`,
      letterSpacing: 0.1,
      textAlign: 'justified',
      color: colors.boulder,
    },
    [theme.breakpoints.up('mobile')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('tablet')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('desktop')]: {
      rowGap: 32,
      gridTemplateColumns: '416px 416px',
    },
  },
  flag: {
    position: 'relative',
    top: 4,
    left: 2,
  },
}));

function ProjectInformation() {
  const classes = useStyles();

  const handleOpenPlayMarket = () =>
    window.open(
      'https://play.google.com/store/apps/details?id=com.simplewealth.onboarding',
      '_blank',
    );
  const handleOpenAppStore = () =>
    window.open(
      'https://apps.apple.com/de/app/simplewealth/id1610294051?l=en',
      '_blank',
    );

  return (
    <S.Wrapper>
      <div className={classes.content}>
        <div className={classes.download}>
          <Button
            onClick={handleOpenAppStore}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <AppStore />
          </Button>
          <Button
            onClick={handleOpenPlayMarket}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <GooglePlay />
          </Button>
          <p className={classes.copyright}>
            &#169; Simplewealth AG 2015-{new Date().getFullYear()}. Made with &#128150; in beautiful
            <img className={classes.flag} src={swissFlag} alt="flag" />
          </p>
        </div>
        <div>
          <div className={classes.sponsors}>
            <img src={vqf} alt="VGQ" />
            <img src={finos} alt="Finos" />
            <img src={swissMade} alt="Swiss Made" />
          </div>
          <div className={classes.information}>
            <p>
              Investing in securities involves risks, and there is always the
              potential of losing money when you invest in securities. Before
              investing, consider your investment objectives and Simplewealth AG
              charges and expenses.
            </p>
            <p>
              Past performance is not an indicator of future performance. Unless
              otherwise specified, all return figures shown above are for
              illustrative purposes only and are not actual customer or model
              returns. Actual returns will vary greatly and depend on personal
              and market conditions.
            </p>
            <p>
              Simplewealth AG is a Swiss-based advisory service, designed to
              assist clients in achieving discrete financial goals. We are not
              intended to provide comprehensive tax advice or financial planning
              with respect to every aspect of a client&apos;s financial
              situation and do not incorporate specific investments that clients
              hold elsewhere.
            </p>
            <p>
              Investing implies your capital is at risk. The value of your
              account depends on market movements and you may get back less than
              you invest.
            </p>
          </div>
        </div>
      </div>
    </S.Wrapper>
  );
}

export default ProjectInformation;
