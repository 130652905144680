import React from 'react';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import clsx from 'clsx';
import PasswordInput from '../Input/PasswordInput';
import S from './styled';
import { HISTORY_LOG_TYPE } from '../../constants';
import { sendHistoryLog } from '../../utils';

function FPasswordInput(props) {
  const handleBlur = () => sendHistoryLog(HISTORY_LOG_TYPE.FIELD, props.name)

  return (
    <Field name={props.name}>
      {({ input, meta }) => {
        const hasError = Boolean(meta.touched && meta.error);
        const inputClasses = clsx(props.className, {
          'has-error': hasError,
        });

        return (
          <S.ErrorWrapper>
            <PasswordInput
              {...props}
              className={inputClasses}
              inputProps={{
                ...input,
                onBlur: handleBlur,
                ...props.inputProps,
                error: hasError,
              }}
            />
            {hasError && <S.Error>{meta.error}</S.Error>}
            {hasError && <S.ErrorIndicator />}
          </S.ErrorWrapper>

        );
      }}
    </Field>
  );
}

FPasswordInput.propTypes = {
  name: string.isRequired,
};

export default FPasswordInput;
