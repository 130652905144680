/* eslint-disable max-len */
const URL = {
  LOGIN: '/clientAuth',
  REGISTER: '/users',
  HISTORY: '/users/user/history',
  RECOVER_PASSWORD: '/users/pass/password',
  VERIFICATION_EMAIL: '/verifications/email',
  CURRENT_USER: '/currentUser',
  BALANCE_AND_DEPOSITS:
    '/ibImport?name=IbImportCSVKeyStatistics&fields[]=EndingNAV&fields[]=CumulativeReturn&fields[]=Deposits/Withdrawals',
  HISTORICAL_PERFORMANCE_BENCHMARK:
    '/ibImport?name=IbImportCSVHistoricalPerformanceBenchmarkComparison&fields[]=Month&fields[]=AccountReturn',
  ALLOCATION_BY_FINANCIAL_INSTRUMENT:
    '/ibImport?name=IbImportCSVAllocationbyFinancialInstrument&fields[]=Date&fields[]=NAV',
  CONCENTRATION_REGION_ALLOCATION:
    '/ibImport?name=IbImportCSVConcentrationRegionAllocation&fields[]=Region&fields[]=LongParsedWeight',
  CONCENTRATION_ASSET_CLASS:
    '/ibImport?name=IbImportCSVConcentrationAssetClassAllocation&fields[]=AssetClass&fields[]=LongParsedWeight',
  CONCENTRATION_HOLDINGS:
    '/ibImport?name=IbImportCSVConcentrationHoldings&fields[]=Symbol&fields[]=Description&fields[]=Sector&fields[]=NetValue&fields[]=NetParsedWeight&limit=20',
  RISK_PROFILES: '/riskProfiles',
  STMT_FUNDS:
    'ibImport?name=IbImportXMLStmtFunds&fields[]=date&fields[]=activityDescription&fields[]=amount&fields[]=balance&limit=6',
};

export default URL;
