import { styled } from '@mui/material';
import { FONTS } from '../../constants';

const Label = styled('p')`
  margin-top: 0;
  margin-bottom: 32px;
  font: normal normal 400 16px/20px ${FONTS};
  letter-spacing: 0;
`;

export default {
  Label,
};
