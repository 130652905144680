import React, { useCallback, useState } from 'react';
import { string, any } from 'prop-types';
import { makeStyles } from '@mui/styles';
import TextInput from './TextInput';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';

const useStyles = makeStyles((theme) => ({
  passwordIcon: {
    marginRight: 10,
    cursor: 'pointer',
    '&:hover path': {
      fill: theme.palette.primary.main,
    },
  },
}));

function PasswordInput({ inputProps, ...rest }) {
  const classes = useStyles();
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((prevState) => !prevState);
  }, []);

  return (
    <TextInput
      inputProps={{
        ...inputProps,
        type: isPasswordVisible ? 'text' : 'password',
        endAdornment: <Eye onClick={togglePasswordVisibility} className={classes.passwordIcon} />,
      }}
      {...rest}
    />
  );
}

PasswordInput.propTypes = {
  name: string.isRequired,
  inputProps: any,
};

export default PasswordInput;
