import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import {
  string, node, any,
} from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FONTS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  input: {
    '& input': {
      font: `normal normal 400 16px/21px ${FONTS}`,
      letterSpacing: 0,
      caretColor: theme.palette.primary.main,
      paddingLeft: 12,
      color: theme.palette.text.primary,
    },
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  label: {
    marginBottom: 6,
    font: `normal normal 400 14px/18px ${FONTS}`,
    letterSpacing: 0,
    color: theme.palette.text.primary,
  },
  wrapper: {
    '&:focus-within label': {
      color: theme.palette.primary.main,
    },
    '&.has-error label': {
      color: theme.palette.error.main,
    },
  },
}));

function TextInput({
  name, label, className, inputProps,
}) {
  const classes = useStyles();
  return (
    <div className={clsx(className, classes.wrapper)}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <OutlinedInput name={name} className={classes.input} fullWidth {...inputProps} />
    </div>
  );
}

TextInput.propTypes = {
  name: string.isRequired,
  label: node,
  inputProps: any,
  className: string,
};

export default TextInput;
