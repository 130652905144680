import axios from 'axios';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from '../components/Login';
import API_SERVICE from '../services/api';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useAuthorization } from '../contexts';

function LoginContainer() {
  useDocumentTitle('Login');
  const { setAuth } = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleSubmit = useCallback(
    async (values) => {
      const response = (await API_SERVICE.login(values)) || {};
      if ([200, 201].includes(response.status) && response.data) {
        setAuth(true);
        navigate(from, { replace: true });
        axios
          .get('https://ipapi.co/json/')
          .then(async (res) => {
            await API_SERVICE.historyLog({
              ip: res.data.ip,
              userId: values.email,
              action: "login",
              msg: "login success",
              keyAction: false,
              email: values.email
            });
          })
      } else {
        axios
          .get('https://ipapi.co/json/')
          .then(async (res) => {
            await API_SERVICE.historyLog({
              ip: res.data.ip,
              userId: values.email,
              action: "loginFailed",
              msg: "login failed",
              keyAction: false,
              email: values.email
            });
          })

      }
    },
    [setAuth, navigate, from],
  );

  return <Login handleSubmit={handleSubmit} />;
}

export default LoginContainer;
