import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NonAuthHeader from '../components/Header';
import AuthHeader from '../components/Header/AuthHeader';
import { useAuthorization, useUser } from '../contexts';

function HeaderContainer() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = useCallback(
    () => setSidebarOpen((prevState) => !prevState),
    [],
  );

  const { isAuth, logout } = useAuthorization();
  const { getUserData, user } = useUser();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (path) => () => {
      if (isAuth) logout();
      navigate(path);
    },
    [isAuth, navigate, logout],
  );

  useEffect(() => {
    if (isAuth) {
      getUserData();
    }
  }, [isAuth, getUserData]);

  if (isAuth) {
    return (
      <AuthHeader
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        navigateToSignIn={handleNavigate('/login')}
        navigateToSignUp={handleNavigate('/registration')}
        user={user}
      />
    );
  }

  return (
    <NonAuthHeader
      isSidebarOpen={isSidebarOpen}
      toggleSidebar={toggleSidebar}
      navigateToSignIn={handleNavigate('/login')}
      navigateToSignUp={handleNavigate('/registration')}
    />
  );
}

export default HeaderContainer;
