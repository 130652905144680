import dayjs from 'dayjs';
import axios from 'axios';
import { HISTORY_LOG_TYPE } from '../constants';
import API_SERVICE from '../services/api';

// e.g. input === '202003'
export const transformToDate = (input) => dayjs(input).format('YYYY, MMM');

export const mergeObjects = (objects) => {
  const merged = {};

  objects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      merged[key] = { ...merged[key], ...obj[key] };
    });
  });

  return merged;
};

export const filterObjProps = (obj, filterFn) => {
  const filtered = {};

  Object.keys(obj).forEach((key) => {
    if (filterFn(obj[key])) {
      filtered[key] = obj[key];
    }
  });

  return filtered;
};

export const generateUI = () =>
  (
    Date.now().toString(36) + Math.random().toString(36).slice(0, 1)
  ).toUpperCase();

export const hasProp = (obj, prop) => Object.hasOwnProperty.call(obj, prop);

export const checkToken = () => document.cookie.includes('token');

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const sendHistoryLog = (type, field, user = null, keyAction = false) => {
  let msg;
  switch (type) {
    case HISTORY_LOG_TYPE.FIELD:
      msg = `field ${field} filled`;
      break;
    case HISTORY_LOG_TYPE.BUTTON:
      msg = `button ${field} clicked`;
      break;
    case HISTORY_LOG_TYPE.EMAIL:
      msg = `email confirmed`;
      break;
    default:
      throw new Error('This type not exist!');
  }
  const fetchData = async () => {
    axios.get('https://ipapi.co/json/').then(async (res) => {
      await API_SERVICE.historyLog({
        ip: res.data.ip,
        userId: user?.email || '',
        action: msg,
        msg,
        keyAction,
        email: user?.email || '',
      });
    });
  };
  fetchData();
};
