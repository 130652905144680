import { useLocation, Outlet, Navigate } from 'react-router-dom';
import React from 'react';
import { useAuthorization } from '../../contexts';

function RequireAuth() {
  const { isAuth } = useAuthorization();
  const location = useLocation();

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
}

export default RequireAuth;
