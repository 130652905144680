import React from 'react';
import { node, string } from 'prop-types';
import S from './styled';

function NavSection({ children, className, label }) {
  return (
    <div className={className}>
      <S.Label>{label}</S.Label>
      {children}
    </div>
  );
}

NavSection.propTypes = {
  label: node.isRequired,
  children: node,
  className: string,
};

export default NavSection;
