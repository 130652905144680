import React from 'react';
import S from './styled';
import Loader from '../../assets/images/loader.gif'

function Circle() {
  return (
    <S.Wrapper>
      <div className="loader" >
        <img src={Loader} alt="loader" />
      </div>
    </S.Wrapper>
  );
}

export default Circle;
