import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import HeaderContainer from '../containers/Header.container';
import SWRoutes from '../routes';
import BottomNavigation from './BottomNavigation';
import ProjectInformation from './ProjectInformation';
import Footer from './Footer';
import AuthorizationProvider from '../contexts/auth.context';
import Background from './Background';
import { colors } from '../theme';
import 'react-toastify/dist/ReactToastify.css';
import UserProvider from '../contexts/user.context';
import OverviewProvider from '../contexts/overview.context';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tablet),
  );

  return (
    <div className="App">
      <AuthorizationProvider>
        <UserProvider>
          <OverviewProvider>
            <Background {...(isMobile && { color: colors.white })}>
              <HeaderContainer />
              <SWRoutes />
              <BottomNavigation />
              <ProjectInformation />
              <Footer />
            </Background>
          </OverviewProvider>
        </UserProvider>
      </AuthorizationProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
